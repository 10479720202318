import React, {Component} from 'react'
import {Link} from 'gatsby'
import config from '../../data/config'
import Helmet from 'react-helmet'
import PostCard from '../components/PostCard'
import HeroBanner from '../components/Hero'

const PaginationLink = props => {
  // console.log(props)
  if (props.to) {
    return (
      <Link to={props.url} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

export default class BlogPage extends Component {
  render () {
    console.log(this.props)

    const {pageContext} = this.props
    const {
      index,
      nodes,
      page,
      prev,
      next,
      pages,
      total,
      shortTitle: siteTitle,
      title,
      subtitle,
    } = pageContext

    const blogHeader = `${total} Blog Post${
      total === 1 ? '' : 's'
    }`

    const hero_settings = {
      size: 'is-medium',
      particle: true,
      images: '',
      css: '#ffafbd,#ffc3a0~#2193b0,#6dd5ed',
      font_color: '#fff',
    }

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    return (
      <div>
        <Helmet>
          <title>Blog | Layer Cake Solutions</title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>

        <HeroBanner
          title={'Blog'}
          subtitle={"Hopefully stuff you'll find interesting"}
          hero_settings={hero_settings}
        />

        <section className='section is-feature-grey edge--top'>
          <div className='container'>
            {/* <div className='buttons is-centered'> */}
            {/* <PaginationLink to={prev} url={prev} text='Previous Page' /> */}
            {/* <PaginationLink to={next} url={next} text='Next Page' /> */}
            {/* </div> */}
            <h1 className='title'>
              {blogHeader}
            </h1>
            <h2 className='subtitle'>
              page {page}
            </h2>
            <PostCard posts={nodes} />
            <div className='buttons is-centered'>
              <PaginationLink to={prev} url={prev} text='Previous Page' />
              <PaginationLink to={next} url={next} text='Next Page' />
            </div>
          </div>
        </section>
      </div>
    )
  }
}
